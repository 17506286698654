<template>
  <div class="Checkout__Step ContactInformation">
    <el-form ref="CustomerInformations" :key="'CustomerInformations'" :model="Checkout" :rules="rules"
      @validate="onCustomerInformationsValidated" class="Checkout__Form--Custom Form--Custom">
      <div class="Checkout__Section">
        <div style="
            display: flex;
            justify-content: space-between;
        ">
          <h2 class="Checkout__Section__Title">Dados pessoais:</h2>
          <small class="Base--title" style="margin: 27px 0 15px 0;" v-if="!loggedUser && CheckoutConfig.generalConfig.enable_login === true">
            <el-link @click="loginPage" underlined>
              <b>
                faça login aqui
              </b>
            </el-link>
          </small>
        </div>
        <el-row :gutter="10">
          <el-col :span="24" input-ref="customer.email">
            <div class="el-form-outer">
              <el-form-item prop="customer.email" :label="AppConfig.translations.forms.contact_information.email.label"
                :class="{ 'el-form-item--active': activeInput === 'Email' }" >
                <el-input :placeholder="AppConfig.translations.forms.contact_information.email.placeholder"
                  ref="Checkout.customer.email"
                  :key="'Checkout.customer.email'"
                  name="Email" autocomplete="email"
                  :disabled="loggedUser && CheckoutConfig.generalConfig.enable_login === true"
                  inputmode="email" v-model.trim="Checkout.customer.email" @focus="setActiveInput('Email')"></el-input>
                <div
                  v-if="isCustomerInformationValid('customer.email') && AppConfig.translations.forms.contact_information.email.input_valid"
                  class="el-form-item__validation el-form-item__success">
                  {{ AppConfig.translations.forms.contact_information.email.input_valid }}</div>
              </el-form-item>
              <div style="
                display: flex;
                justify-content: space-between;
            " v-if="loggedUser && CheckoutConfig.generalConfig.enable_login === true">
              <small class="Base--title" style="margin: 10px 0 0 0;">
                <el-link @click="redirectToLogout" underlined>
                  <b>
                    sair
                  </b>
                </el-link>
              </small>
            </div>
            </div>
          </el-col>
          <div v-if="!AppConfig.contact_information.optin_bottom">
            <el-col :span="24" v-if="!HasRewardProgram">
              <div class="el-form-outer">
                <el-checkbox placeholder="Optin" ref="Checkout.customer.optin" :key="'Checkout.customer.optin'"
                  v-model="Checkout_customer_optin" @focus="setActiveInput('Optin')" @blur="setActiveInput(null)">{{
                    Checkout_ContactInformation_CustomerDocument_OptIn_Label }}</el-checkbox>
              </div>
            </el-col>
          </div>
          <el-col :span="24" v-if="HasRewardProgram">
            <LoyaltyProgramOptIn />
          </el-col>
          <el-col :span="24" v-if="loggedUser && CheckoutConfig.generalConfig.enable_login === true">
            <ContactAddressSelector @addressSelected="onSavedAddressSelected" />
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :span="24" v-if="CheckoutConfig.customerInformationConfig.company_doc"
            input-ref="Checkout_customer_type">
            <br>
            <div class="el-form-outer">
              <el-form-item class="el-form-item--radio"
                :class="{ 'el-form-item--active': activeInput === 'customer_type--enabled' }">
                <el-radio-group inline ref="Checkout_customer_type" :key="Checkout_customer_type"
                  v-model="Checkout_customer_type">
                  <el-radio label="b2c" :disabled="disablePreRegisterFields === true && Checkout_customer_type === 'b2b'">Pessoa Física</el-radio>
                  <el-radio label="b2b" :disabled="disablePreRegisterFields === true && Checkout_customer_type === 'b2c'">Pessoa Jurídica</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row :key="'ContactInfoB2CForm'" :gutter="10" v-if="Checkout_customer_type === 'b2c'">
          <!-- <ContactInfoB2CForm
            ref="Checkout.contact_info.b2c_form"
            :key="'Checkout.contact_info.b2c_form'"
          /> -->
          <el-col :xs="24" :sm="24" :md="12" :span="12" input-ref="ContactInfoB2CForm.Checkout.customer.first_name">
            <div class="el-form-outer">
              <el-form-item prop="customer.first_name"
                :label="AppConfig.translations.forms.contact_information.first_name.label"
                :class="{ 'el-form-item--active': activeInput === 'Nome' }" :rules="[
                  { required: true, trigger: 'blur', validator: validateName }
                ]">
                <el-input :placeholder="AppConfig.translations.forms.contact_information.first_name.placeholder"
                  v-model="Checkout.customer.first_name" ref="ContactInfoB2CForm.Checkout.customer.first_name"
                  :key="'ContactInfoB2CForm.Checkout.customer.first_name'" name="given-name" autocomplete="given-name"
                  :disabled="preFilledCustomerFields.includes('firstName') && disablePreRegisterFields === true"
                  @focus="setActiveInput('Nome')"
                  @blur="() => { setActiveInput(null); setDefaultReceiverName() }"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :span="12" input-ref="ContactInfoB2CForm.Checkout.customer.last_name">
            <div class="el-form-outer">
              <el-form-item prop="customer.last_name"
                :label="AppConfig.translations.forms.contact_information.last_name.label"
                :class="{ 'el-form-item--active': activeInput === 'Sobrenome' }" :rules="[
                  { required: true, trigger: 'blur', validator: validateLastName }
                ]">
                <el-input :placeholder="AppConfig.translations.forms.contact_information.last_name.placeholder"
                  v-model="Checkout.customer.last_name" ref="ContactInfoB2CForm.Checkout.customer.last_name"
                  :key="'ContactInfoB2CForm.Checkout.customer.last_name'" name="family-name" autocomplete="family-name"
                  :disabled="preFilledCustomerFields.includes('lastName') && disablePreRegisterFields === true"
                  @focus="setActiveInput('Sobrenome')"
                  @blur="() => { setActiveInput(null); setDefaultReceiverName() }"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col v-if="CheckoutConfig.generalConfig.date_of_birth_field_enabled" :xs="24" :sm="24" :md="12" :span="12"
            input-ref="ContactInfoB2CForm.Checkout.customer.date_of_birth">
            <div class="el-form-outer">
              <el-form-item prop="customer.date_of_birth" :label="'Data de Nascimento'"
                :class="{ 'el-form-item--active': activeInput === 'Data de Nascimento' }" :rules="[
                  {
                    required: false,
                    message: `Digite sua data de nascimento`,
                    trigger: 'change'
                  },
                  { validator: this.ValidateBirthDate, trigger: 'blur' }
                ]">
                <el-input :label="'Data de Nascimento'" v-model="Checkout.customer.date_of_birth"
                  ref="ContactInfoB2CForm.Checkout.customer.date_of_birth"
                  :key="'ContactInfoB2CForm.Checkout.customer.date_of_birth'" name="birth_date" autocomplete="birth_date"
                  pattern="\d*" inputmode="numeric" v-facade="['##/##/####']"
                  @focus="setActiveInput('Data de Nascimento')" @blur="setActiveInput(null)"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :span="12" input-ref="ContactInfoB2CForm.Checkout.customer.document">
            <div class="el-form-outer">
              <el-form-item prop="customer.document" :label="'CPF'"
                :class="{ 'el-form-item--active': activeInput === 'CPF' }" :rules="[
                  {
                    required: true,
                    message: `Digite seu CPF OU CNPJ`,
                    trigger: 'blur'
                  },
                  { validator: this.ValidateUserDocument, trigger: 'blur' }
                ]">
                <el-input :label="'CPF'" v-model="Checkout.customer.document"
                  ref="ContactInfoB2CForm.Checkout.customer.document"
                  :key="'ContactInfoB2CForm.Checkout.customer.document'" name="organization" autocomplete="organization"
                  pattern="\d*" inputmode="numeric" v-facade="['###.###.###-##']"
                  :disabled="preFilledCustomerFields.includes('document') && disablePreRegisterFields === true" @focus="setActiveInput('CPF')"
                  @blur="setActiveInput(null)"></el-input>
              </el-form-item>
              <!-- <div class="el-form--error" v-if="Checkout.customer.document.$error && (!Checkout.customer.document.required || !Checkout.customer.document.UserDocument)">Password is required.</div> -->
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="12" :span="12" input-ref="ContactInfoB2CForm.Checkout.customer.phone">
            <div class="el-form-outer">
              <el-form-item prop="customer.phone" :label="AppConfig.translations.forms.contact_information.phone.label"
                :class="{ 'el-form-item--active': activeInput === 'Telefone' }" :rules="[
                  { required: true, message: 'Digite seu telefone', trigger: 'change' },
                  { validator: this.ValidatePhoneNumber, trigger: 'blur' }
                ]">
                <el-input :placeholder="AppConfig.translations.forms.contact_information.phone.placeholder"
                  v-model="Checkout.customer.phone" ref="ContactInfoB2CForm.Checkout.customer.phone"
                  :key="'ContactInfoB2CForm.Checkout.customer.phone'" name="tel" autocomplete="tel" pattern="\d*"
                  inputmode="numeric" v-facade="Checkout_ContactInformation_CustomerPhone_Mask"
                  :disabled="preFilledCustomerFields.includes('phone') && disablePreRegisterFields === true"
                  @focus="setActiveInput('Telefone')" @blur="setActiveInput(null)"></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row :key="'ContactInfoB2BForm'" :gutter="10" v-if="Checkout_customer_type === 'b2b'">
          <!-- <ContactInfoB2BForm
            ref="Checkout.contact_info.b2b_form"
            :key="'Checkout.contact_info.b2b_form'"
          /> -->
          <el-col :xs="24" :sm="24" :md="24" :span="24" input-ref="ContactInfoB2BForm.Checkout.customer.first_name">
            <div class="el-form-outer">
              <el-form-item prop="customer.first_name"
                :label="AppConfig.translations.forms.contact_information.company_name.label"
                :class="{ 'el-form-item--active': activeInput === 'Razão Social' }" :rules="[
                  { required: true, message: 'Digite sua razão social', trigger: 'blur' }
                ]">
                <el-input :placeholder="AppConfig.translations.forms.contact_information.company_name.placeholder"
                  v-model="Checkout.customer.first_name" ref="ContactInfoB2BForm.Checkout.customer.first_name"
                  :key="'ContactInfoB2BForm.Checkout.customer.first_name'" name="given-name" autocomplete="given-name"
                  :disabled="preFilledCustomerFields.includes('firstName') && disablePreRegisterFields === true"
                  @focus="setActiveInput('Razão Social')"
                  @blur="() => { setActiveInput(null); setDefaultReceiverName() }"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :span="24" input-ref="ContactInfoB2BForm.Checkout.customer.document">
            <div class="el-form-outer">
              <el-form-item prop="customer.document" :label="'CNPJ'"
                :class="{ 'el-form-item--active': activeInput === 'CNPJ' }" :rules="[
                  {
                    required: true,
                    message: `Digite seu CPF OU CNPJ`,
                    trigger: 'blur'
                  },
                  { validator: this.ValidateUserDocument, trigger: 'blur' }
                ]">
                <el-input :label="'CNPJ'" v-model="Checkout.customer.document"
                  ref="ContactInfoB2BForm.Checkout.customer.document"
                  :key="'ContactInfoB2BForm.Checkout.customer.document'" name="organization" autocomplete="organization"
                  pattern="\d*" inputmode="numeric" v-facade="['##.###.###/####-##']"
                  :disabled="preFilledCustomerFields.includes('document') && disablePreRegisterFields === true"
                  @focus="setActiveInput('CNPJ')"
                  @blur="setActiveInput(null)"></el-input>
              </el-form-item>
              <!-- <div class="el-form--error" v-if="Checkout.customer.document.$error && (!Checkout.customer.document.required || !Checkout.customer.document.UserDocument)">Password is required.</div> -->
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :span="24"
            v-if="CheckoutConfig && CheckoutConfig.customerInformationConfig && CheckoutConfig.customerInformationConfig.comapany_register_id"
            input-ref="ContactInfoB2BForm.Checkout.customer.state_subscription">
            <div class="el-form-outer">
              <el-form-item prop="customer.state_subscription"
                :label="AppConfig.translations.forms.contact_information.state_subscription.label"
                :class="{ 'el-form-item--active': activeInput === 'Inscricao Estadual' }" :rules="[
                  { required: true, message: 'Digite sua inscrição estadual', trigger: 'change' },
                  { required: true, message: 'Digite sua inscrição estadual', trigger: 'blur' },
                  { validator: this.validaInscricaoEstadual, trigger: 'blur' }
                ]">
                <el-input :label="AppConfig.translations.forms.contact_information.state_subscription.label"
                  v-model="Checkout.customer.state_subscription"
                  ref="ContactInfoB2BForm.Checkout.customer.state_subscription"
                  :key="'ContactInfoB2BForm.Checkout.customer.state_subscription'"
                  :disabled="preFilledCustomerFields.includes('stateRegistration') && disablePreRegisterFields === true"
                  @focus="setActiveInput('Inscricao Estadual')" @blur="setActiveInput(null)">
                  <el-checkbox slot="append" placeholder="StateSubscription" @focus="setActiveInput('StateSubscription')"
                    @blur="setActiveInput(null)" v-model="StateSubscriptionToggle" :label="'Isento'" :key="'Isento'"
                    ref="ContactInfoB2BForm.StateSubscriptionToggle"
                    @change="updateStateSubscription">Isento</el-checkbox>
                </el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :span="12"
            v-if="AppConfig.custom_attributes.customer_b2b_resell && AppConfig.custom_attributes.customer_b2b_resell.enabled"
            input-ref="ContactInfoB2BForm.Checkout.customer.customer_b2b_resell">
            <CustomAttribute :attribute_key="'customer_b2b_resell'"
              :attribute_value="AppConfig.custom_attributes.customer_b2b_resell.field.value"
              :type="AppConfig.custom_attributes.customer_b2b_resell.field.type"
              :label="AppConfig.custom_attributes.customer_b2b_resell.field.label"
              :placeholder="AppConfig.custom_attributes.customer_b2b_resell.field.placeholder"
              :required="AppConfig.custom_attributes.customer_b2b_resell.field.required" />
          </el-col>
          <el-col :xs="24" :sm="24" :md="Checkout_customer_type === 'b2b' ? 24 : 12"
            :span="Checkout_customer_type === 'b2b' ? 24 : 12" input-ref="ContactInfoB2BForm.Checkout.customer.phone">
            <div class="el-form-outer">
              <el-form-item prop="customer.phone" :label="AppConfig.translations.forms.contact_information.phone.label"
                :class="{ 'el-form-item--active': activeInput === 'Telefone' }" :rules="[
                  { required: true, message: 'Digite seu telefone', trigger: 'change' },
                  { validator: this.ValidatePhoneNumber, trigger: 'blur' }
                ]">
                <el-input :placeholder="AppConfig.translations.forms.contact_information.phone.placeholder"
                  v-model="Checkout.customer.phone" ref="ContactInfoB2BForm.Checkout.customer.phone"
                  :key="'ContactInfoB2BForm.Checkout.customer.phone'" name="tel" autocomplete="tel" pattern="\d*"
                  inputmode="numeric" v-facade="Checkout_ContactInformation_CustomerPhone_Mask"
                  :disabled="preFilledCustomerFields.includes('phone') && disablePreRegisterFields === true"
                  @focus="setActiveInput('Telefone')" @blur="setActiveInput(null)"></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="Checkout__Section">
        <h2 class="Checkout__Section__Title">{{
          AppConfig.translations.labels === undefined ||
          AppConfig.translations.labels === null ||
          AppConfig.translations.labels.shipping_info_title === null ?
          "Dados de envio:" : AppConfig.translations.labels.shipping_info_title }}</h2>
        <el-row :gutter="10">
          <el-col :xs="16" :sm="16" :md="12" :span="12" input-ref="shipping_info.address.zip_code">
            <div class="el-form-outer">
              <el-form-item prop="shipping_info.address.zip_code"
                :label="AppConfig.translations.forms.contact_information.zipcode.label"
                :class="{ 'el-form-item--active': activeInput === 'CEP' }">
                <el-input :placeholder="AppConfig.translations.forms.contact_information.zipcode.placeholder"
                  ref="Checkout.shipping_info.address.zip_code" :key="'Checkout.shipping_info.address.zip_code'"
                  v-model="Checkout.shipping_info.address.zip_code"
                  :disabled="preFilledAddressFields.includes('zip_code')" name="postal-code" autocomplete="postal-code"
                  pattern="\d*" inputmode="numeric" v-facade="'#####-###'" @focus="setActiveInput('CEP')"
                  @blur="setActiveInput(null)"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :xs="8" :sm="8" :md="12" :span="12">
            <el-button type="text" size="mini" class="el-button--input" @click="setFindZipCodeModal()">Não sei meu
              CEP</el-button>
          </el-col>
        </el-row>
        <div :loading="zipLoading">
          <el-row :gutter="10" v-show="showAddressForm || !objectIsEmpty(addressForm)">
            <el-col :xs="24" :sm="24" :md="24" :span="24" input-ref="shipping_info.person.first_name">
              <div class="el-form-outer">
                <el-form-item prop="shipping_info.person.first_name"
                  :label="AppConfig.translations.forms.contact_information.shipping_person.label"
                  :class="{ 'el-form-item--active': activeInput === 'Nome do destinatário' }">
                  <el-input :placeholder="AppConfig.translations.forms.contact_information.shipping_person.placeholder"
                    ref="Checkout.shipping_info.person.first_name" :key="'Checkout.shipping_info.person.first_name'"
                    :disabled="receiverFullName !== '' && disablePreRegisterFields === true"
                    v-model="receiverFullName" @focus="setActiveInput('Nome do destinatário')"
                    @blur="() => { setActiveInput(null) }"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="16" input-ref="shipping_info.address.street">
              <div class="el-form-outer">
                <el-form-item prop="shipping_info.address.street"
                  :label="AppConfig.translations.forms.contact_information.address_street.label"
                  :class="{ 'el-form-item--active': activeInput === 'Endereço', 'el-form-item--prefilled is-prefilled': preFilledAddressFields.includes('street') }">
                  <el-input :placeholder="AppConfig.translations.forms.contact_information.address_street.placeholder"
                    ref="Checkout.shipping_info.address.street" :key="'Checkout.shipping_info.address.street'"
                    v-model.lazy="Checkout.shipping_info.address.street" @keyup.native="formatAddress" minlength="3"
                    maxlength="30" name="street-address" autocomplete="street-address"
                    :disabled="preFilledAddressFields.includes('street')" @focus="setActiveInput('Endereço')"
                    @blur="setActiveInput(null)"></el-input>
                </el-form-item>
              </div>
            </el-col>

            <el-col :xs="16" :sm="16" :md="4" input-ref="shipping_info.address.street_number">
              <div class="el-form-outer">
                <el-form-item prop="shipping_info.address.street_number"
                  :label="AppConfig.translations.forms.contact_information.address_street_number.label"
                  :class="{ 'el-form-item--active': activeInput === 'Número', 'el-form-item--prefilled is-prefilled': preFilledAddressFields.includes('street_number') }">
                  <el-input
                    :placeholder="AppConfig.translations.forms.contact_information.address_street_number.placeholder"
                    ref="Checkout.shipping_info.address.street_number"
                    :key="'Checkout.shipping_info.address.street_number'"
                    v-model="Checkout.shipping_info.address.street_number"
                    :disabled="preFilledAddressFields.includes('street_number') || (preFilledAddressFields.includes('number') && disablePreRegisterFields === true)" name="address-line2"
                    autocomplete="address-line2" pattern="\d*" inputmode="numeric" v-facade="'######'"
                    @focus="setActiveInput('Número')" @blur="setActiveInput(null)"></el-input>
                </el-form-item>
              </div>
            </el-col>

            <el-col :xs="8" :sm="8" :md="4" input-ref="shipping_info.address.street_number_none">
              <el-button type="text" size="mini" class="el-button--input" @click="setDefaultStreetNumber()">
                Sem número
              </el-button>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :span="12" input-ref="shipping_info.address.complement">
              <div class="el-form-outer">
                <el-form-item
                  :placeholder="AppConfig.translations.forms.contact_information.address_complement.placeholder"
                  prop="shipping_info.address.complement"
                  :label="AppConfig.translations.forms.contact_information.address_complement.label"
                  :class="{ 'el-form-item--active': activeInput === 'Complemento', 'el-form-item--prefilled is-prefilled': preFilledAddressFields.includes('complement') }"
                  :rules="[
                    {
                      required: false,
                      message: `Digite seu complemento`,
                      trigger: 'change'
                    },
                    { validator: this.ValidateComplement, trigger: 'blur' }
                  ]">
                  <el-input :placeholder="AppConfig.translations.forms.contact_information.address_complement.placeholder"
                    ref="Checkout.shipping_info.address.complement" :key="'Checkout.shipping_info.address.complement'"
                    v-model="Checkout.shipping_info.address.complement"
                    :disabled="preFilledAddressFields.includes('complement')" name="address-line3"
                    autocomplete="address-line3" maxlength="30" @focus="setActiveInput('Complemento')"
                    @blur="setActiveInput(null)"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :span="12" input-ref="shipping_info.address.neighborhood">
              <div class="el-form-outer">
                <el-form-item prop="shipping_info.address.neighborhood"
                  :label="AppConfig.translations.forms.contact_information.address_neighborhood.label"
                  :class="{ 'el-form-item--active': activeInput === 'Bairro', 'el-form-item--prefilled is-prefilled': preFilledAddressFields.includes('neighborhood') }">
                  <el-input
                    :placeholder="AppConfig.translations.forms.contact_information.address_neighborhood.placeholder"
                    ref="Checkout.shipping_info.address.neighborhood" :key="'Checkout.shipping_info.address.neighborhood'"
                    v-model="Checkout.shipping_info.address.neighborhood"
                    :disabled="preFilledAddressFields.includes('neighborhood')" name="address-level3"
                    autocomplete="address-level3" maxlength="30" @focus="setActiveInput('Bairro')"
                    @blur="setActiveInput(null)"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :span="12" input-ref="shipping_info.address.city">
              <div class="el-form-outer">
                <el-form-item prop="shipping_info.address.city"
                  :label="AppConfig.translations.forms.contact_information.address_city.label"
                  :class="{ 'el-form-item--active': activeInput === 'Cidade', 'el-form-item--prefilled is-prefilled': preFilledAddressFields.includes('city') }">
                  <el-input :placeholder="AppConfig.translations.forms.contact_information.address_city.placeholder"
                    ref="Checkout.shipping_info.address.city" :key="'Checkout.shipping_info.address.city'"
                    v-model="Checkout.shipping_info.address.city" :disabled="preFilledAddressFields.includes('city')"
                    name="address-level2" autocomplete="address-level2" @focus="setActiveInput('Cidade')"
                    @blur="setActiveInput(null)"></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :span="12" input-ref="shipping_info.address.state">
              <div class="el-form-outer">
                <el-form-item prop="shipping_info.address.state"
                  :label="AppConfig.translations.forms.contact_information.address_state.label"
                  :class="{ 'el-form-item--active': activeInput === 'Estado', 'el-form-item--prefilled is-prefilled': preFilledAddressFields.includes('state') }">
                  <el-select ref="Checkout.shipping_info.address.state" :key="'Checkout.shipping_info.address.state'"
                    v-model="Checkout.shipping_info.address.state"
                    :disabled="!AppConfig.translations.forms.contact_information.address_state.editable || preFilledAddressFields.includes('state')"
                    name="address-level1" autocomplete="address-level1"
                    :placeholder="AppConfig.translations.forms.contact_information.address_state.placeholder"
                    @focus="setActiveInput('Estado')" @blur="setActiveInput(null)">
                    <el-option v-for="item in states" :key="item.uf" :label="item.name" :value="item.name"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :span="24"
              v-if="AppConfig.custom_attributes.gift_message_from && AppConfig.custom_attributes.gift_message_from.enabled && AppConfig.custom_attributes.gift_message_to && AppConfig.custom_attributes.gift_message_to.enabled && AppConfig.custom_attributes.gift_message_content && AppConfig.custom_attributes.gift_message_content.enabled">
              <GiftMessageWidget :title="'Deseja enviar uma mensagem personalizada?'" :subtitle="null"
                ref="Checkout.gift_message" :key="'Checkout.gift_message'" />
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="10">
          <el-col :span="24" v-if="alert">
            <div class="el-form-outer">
              <el-alert title="Inscrição Estadual" type="error" description="Inscrição estadual inválida" show-icon
                @close="setAlert('close')"></el-alert>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :span="24" v-for="(upsell, index) in AppConfig.upsell_products" :key="index">
            <ProductUpsellSelector :variant="upsell.upsell_variant" :title="upsell.upsell_title"
              :subtitle="upsell.upsell_description" />
          </el-col>
          <el-col :span="24" v-if="HasRewardProgram">
            <div class="el-form-outer">
              <el-checkbox placeholder="Optin" ref="Checkout.customer.optin" :key="'Checkout.customer.optin'"
                v-model="Checkout_customer_optin" @focus="setActiveInput('Optin')" @blur="setActiveInput(null)">{{
                  Checkout_ContactInformation_CustomerDocument_OptIn_Label }}</el-checkbox>
            </div>
          </el-col>
          <div v-if="AppConfig.contact_information.optin_bottom">
            <el-col :span="24" v-if="!HasRewardProgram">
              <div class="el-form-outer">
                <el-checkbox placeholder="Optin" ref="Checkout.customer.optin" :key="'Checkout.customer.optin'"
                  v-model="Checkout_customer_optin" @focus="setActiveInput('Optin')" @blur="setActiveInput(null)">{{
                    Checkout_ContactInformation_CustomerDocument_OptIn_Label }}</el-checkbox>
              </div>
            </el-col>
          </div>
          <el-col :span="12" :offset="6" input-ref="button.continue">
            <el-button type="primary" class="el-button--block Checkout__Button--primary"
              @click="nextStep('CustomerInformations')" :loading="CheckoutStatus.ContactInfos.update === 'LOADING'">
              Continuar
              <i class="el-icon-arrow-right"></i>
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <FindZipCodeModal :visible="showFindZipCodeModal" @modal-closed="setFindZipCodeModal(false)" />
  </div>
</template>

<script>
import FindZipCodeModal from '@/components/contactInfo/FindZipCodeModal.vue'
import ProductUpsellSelector from '@/components/contactInfo/ProductUpsellSelector.vue'
import CustomAttribute from '@/components/_general/CustomAttribute.vue'
// import ContactInfoB2CForm from '@/components/contactInfo/ContactInfoB2CForm.vue'
// import ContactInfoB2BForm from '@/components/contactInfo/ContactInfoB2BForm.vue'
import GiftMessageWidget from '@/components/_general/GiftMessageWidget.vue'
import LoyaltyProgramOptIn from '@/components/_loyaltyProgram/LoyaltyProgramOptIn.vue'
import EventBus from '@/helpers/eventBus'
import { mapFilters } from '@/helpers/filters'
import geoMixin from '@/_mixins/geoMixin'
import { mapActions, mapGetters } from 'vuex'
import ContactAddressSelector from '@/components/contactInfo/ContactAddressSelector.vue'

export default {
  name: 'ContactInformation',
  components: {
    FindZipCodeModal,
    ProductUpsellSelector,
    // ContactInfoB2CForm,
    // ContactInfoB2BForm,
    LoyaltyProgramOptIn,
    CustomAttribute,
    GiftMessageWidget,
    ContactAddressSelector
  },
  mixins: [geoMixin],
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      receiverFullName: null,
      Checkout_ContactInformation_CustomerDocument_Label: 'CPF',
      Checkout_ContactInformation_CustomerDocument_Mask: ['###.###.###-##'],
      Checkout_ContactInformation_CustomerPhone_Mask: [],
      StateSubscriptionToggle: false,
      Checkout_customer_optin: true,
      Checkout_customer_type: null,
      disablePreRegisterFields: false,
      alert: false,
      activeInput: '',
      showAddressForm: false,
      showFindZipCodeModal: false,
      preFilledAddressFields: [],
      preFilledCustomerFields: [],
      validCustomerInformations: [],
      rules: {
        'customer.email': [
          { required: true, message: 'Digite seu email', trigger: 'change' },
          { required: true, message: 'Digite seu email', trigger: 'blur' },
          { validator: this.ValidateEmail, trigger: 'blur' }
        ],
        'shipping_info.address.zip_code': [
          { required: true, message: 'Digite seu CEP', trigger: 'change' },
          // { min: 8, max: 8, message: 'CEP deve ter 8 dígitos', trigger: 'change' },
          { validator: this.ValidateZip, trigger: 'change' }
        ],
        'shipping_info.address.street': [
          { required: true, message: 'Digite sua rua', trigger: 'change' },
          { min: 3, message: 'Digite sua rua', trigger: 'change' }
        ],
        'shipping_info.address.complement': [
          // { required: true, message: 'Digite o complemento', trigger: 'change' }
        ],
        'shipping_info.address.street_number': [
          { required: true, message: 'Digite o número', trigger: 'change' }
        ],
        'shipping_info.address.neighborhood': [
          { required: true, message: 'Digite seu bairro', trigger: 'change' }
        ],
        'shipping_info.address.city': [
          { required: true, message: 'Digite sua cidade', trigger: 'change' }
        ],
        'shipping_info.address.state': [
          { required: true, message: 'Digite seu estado', trigger: 'change' }
        ],
        'shipping_info.person.first_name': [
          { required: true, message: 'Digite o nome do destinatário', trigger: 'change' },
          { validator: this.ShippingPersonNameValidator, trigger: 'change' }
        ],
        'shipping_info.person.last_name': [
          { required: true, message: 'Digite o sobrenome do destinatário', trigger: 'change' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters(['AppConfig', 'Cart', 'Checkout', 'CheckoutStatus', 'CheckoutConfig', 'CustomAttributes']),
    HasRewardProgram() {
      return this.AppConfig.contact_information.has_reward_program
    },
    loggedUser() {
      var loginCookie = localStorage.getItem('login')
      var customerId = localStorage.getItem('customer_id')
      return !!loginCookie && loginCookie === 'true' && !!customerId
    },
    Checkout_ContactInformation_CustomerDocument_OptIn_Label() {
      let $optInMessage = null
      if (this.AppConfig.translations.forms.contact_information.opt_in.label) {
        $optInMessage = this.AppConfig.translations.forms.contact_information.opt_in.label.replace(/{{AppConfig.name}}/g, this.AppConfig.name)
      }
      return $optInMessage
    }
  },
  watch: {
    receiverFullName(val) {
      if (!val) {
        // this.receiverFullName = `${this.Checkout.customer.first_name ? this.Checkout.customer.first_name : ''} ${this.Checkout.customer.last_name ? this.Checkout.customer.last_name : ''}`
      }
      let names = val.split(' ')
      this.UpdateCheckoutState({
        shipping_info: {
          ...this.Checkout.shipping_info,
          person: {
            ...this.Checkout.shipping_info.person,
            first_name: names.shift(),
            last_name: names.join(' ').trim()
          }
        }
      })
    },
    Checkout_customer_type: {
      deep: true,
      immediate: true,
      handler: async function (val, oldVal) {
        let valueChanged = !!oldVal
        if (val === 'b2c') {
          this.Checkout_ContactInformation_CustomerDocument_Mask = ['###.###.###-##']
          this.Checkout_ContactInformation_CustomerDocument_Label = 'CPF'
          if (valueChanged) {
            if (this.disablePreRegisterFields !== true) {
              this.UpdateCheckoutState({
                customer: {
                  ...this.Checkout.customer,
                  first_name: '',
                  last_name: '',
                  document: ''
                }
              })
            }
          }
        }
        if (val === 'b2b') {
          this.Checkout_ContactInformation_CustomerDocument_Mask = ['##.###.###/####-##']
          this.Checkout_ContactInformation_CustomerDocument_Label = 'CNPJ'

          if (valueChanged) {
            if (this.disablePreRegisterFields !== true) {
              this.UpdateCheckoutState({
                customer: {
                  ...this.Checkout.customer,
                  first_name: '',
                  last_name: '.',
                  document: ''
                }
              })
            }
          }
        }
        if (this.$refs['CustomerInformations']) {
          // console.log('RESET')
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs['CustomerInformations'].clearValidate()
              this.focusFirstInput()
            }, 500)
          })
        }
      }
    }
  },
  created() {
    let $this = this
    $this.documentCustomerTypeConfig()
    $this.documentPhoneConfig()
    $this.initContactInformationsEvents()
    // $this.receiverFullName = `${$this.Checkout.customer.first_name ? $this.Checkout.customer.first_name : ''} ${$this.Checkout.customer.last_name ? $this.Checkout.customer.last_name : ''}`
    if ($this.Checkout.shipping_info.person.first_name) {
      $this.receiverFullName = `${$this.Checkout.shipping_info.person.first_name ? $this.Checkout.shipping_info.person.first_name.trim() : ''} ${$this.Checkout.shipping_info.person.last_name ? $this.Checkout.shipping_info.person.last_name.trim() : ''}`.trim()
    } else {
      $this.receiverFullName = `${$this.Checkout.customer.first_name ? $this.Checkout.customer.first_name.trim() : ''} ${$this.Checkout.customer.last_name ? $this.Checkout.customer.last_name.trim() : ''}`.trim()
    }
  },
  mounted() {
    // this.setUpDateOfBirthField();

    // let $this = this
    // $this.focusFirstInput()
  },
  methods: {
    ...mapFilters([
      'objectIsEmpty',
      'checkEmailFormat',
      'checkUserDocument',
      'checkBirthDate',
      'checkPhoneNumber',
      'checkStateSubscription'
    ]),

    ...mapActions([
      'SetCheckoutState',
      'UpdateCheckoutState',
      'UpdateCheckout',
      'goToNextStep',
      'sendContactInformation',
      'setCheckoutStatus',
      'SetCustomAttribute',
      'SendCustomAttributes',
      'DeleteCustomAttribute'
    ]),
    setUpDateOfBirthField() {
      var datePicker = document.getElementById('datePicker')
      datePicker.setAttribute('maxlength', '10')
    },
    initContactInformationsEvents() {
      let $this = this
      EventBus.$on('checkout-email-changed', function (Checkout) {
        $this.UpdateCheckoutState({
          discount_codes: [...Checkout.discount_codes],
          messages: [...Checkout.messages],
          totals: Checkout.totals,
          products: [...Checkout.products]
        })
      })
    },

    formatAddress(e) {
      if (/[^A-Za-zÀ-ÖØ-öø-ÿ0-9 ]/g.test(e.target.value)) {
        this.Checkout.shipping_info.address.street = e.target.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9 ]/g, '').replace('  ', '')
      }
    },
    documentCustomerTypeConfig() {
      if (this.Checkout.customer.document) {
        this.Checkout_customer_type = this.Checkout.customer.document.replace(/[^\d]+/g, '').length <= 11 ? 'b2c' : 'b2b'
        return
      }
      this.Checkout_customer_type = 'b2c'
    },
    documentPhoneConfig() {
      if (this.AppConfig.contact_information.fields.phone.enable_fixed_phone === true) {
        this.Checkout_ContactInformation_CustomerPhone_Mask.push('(##) ####-####')
      }
      if (this.AppConfig.contact_information.fields.phone.enable_mobile_phone === true) {
        this.Checkout_ContactInformation_CustomerPhone_Mask.push('(##) #####-####')
      }
    },
    updateStateSubscription(val) {
      this.Checkout.customer.state_subscription = val ? 'Isento' : ''
    },
    setDefaultStreetNumber() {
      this.UpdateCheckoutState({
        shipping_info: {
          ...this.Checkout.shipping_info,
          address: {
            ...this.Checkout.shipping_info.address,
            street_number: '0'
          }
        }
      })
    },
    validateName(rule, value, callback) {
      if (value === null || value === undefined || value === '' || value.length < 0) {
        return callback(new Error('Digite seu nome'))
      }
      // eslint-disable-next-line no-useless-escape
      if (/[^a-zA-ZÀ-ú0-9\- \/]/.test(value)) {
        return callback(new Error(`Caracteres inválidos`))
      }
      return callback()
    },
    validateLastName(rule, value, callback) {
      if (value === null || value === undefined || value === '' || value.length < 0) {
        return callback(new Error('Digite seu sobrenome'))
      }
      // eslint-disable-next-line no-useless-escape
      if (/[^a-zA-ZÀ-ú0-9\- \/]/.test(value)) {
        return callback(new Error(`Caracteres inválidos`))
      }
      return callback()
    },
    ValidatePhoneNumber(rule, value, callback) {
      if (!this.checkPhoneNumber(value)) {
        return callback(new Error(`Número inválido`))
      } else {
        callback()
      }
    },
    ValidateUserDocument(rule, value, callback) {
      if (!this.checkUserDocument(value)) {
        if (!value) {
          return callback(
            new Error(`Digite um ${this.CheckoutConfig.customerInformationConfig.company_doc ? `${this.Checkout_customer_type === 'b2c' ? `CPF` : `CNPJ`}` : `CPF ou CNPJ`}`)
          )
        }
        return callback(
          new Error(
            `${value ? value.replace(/[^\d]+/g, '').length <= 11 ? 'CPF' : 'CNPJ' : 'Documento'
            } inválido`
          )
        )
      } else {
        callback()
      }
    },
    ValidateComplement(rule, value, callback) {
      // eslint-disable-next-line no-useless-escape
      let hasSpecialCharRegex = /[^a-zA-ZÀ-ú0-9-,.\- \/]/
      if (hasSpecialCharRegex.test(value)) {
        return callback(new Error(`Caracteres inválidos`))
      }
      return callback()
    },
    ValidateBirthDate(rule, value, callback) {
      if (!this.checkBirthDate(value)) {
        if (!value) {
          return callback(
            new Error(`Digite uma data de nascimento`)
          )
        }
        return callback(
          new Error(
            `Data de nascimento inválida`
          )
        )
      } else {
        callback()
      }
    },
    validaInscricaoEstadual(rule, value, callback) {
      if (this.Checkout.customer.state_subscription) {
        if (this.Checkout.customer.state_subscription === 'Isento') {
          this.SetCustomAttribute(['inscricao_estadual', this.Checkout.customer.state_subscription])
          if (this.CustomAttributes) { this.SendCustomAttributes() }
          return callback()
        }
        if (this.Checkout.customer.document && this.Checkout.customer.document.replace(/[^\d]+/g, '').length >= 14 && this.Checkout.shipping_info.address.state && this.Checkout.customer.state_subscription) {
          let foundStateOBJ = this.states.filter((state) => {
            return state.name.toLowerCase() === this.Checkout.shipping_info.address.state.toLowerCase()
          })[0]
          let StateSubscriptionOBJ = {
            State: foundStateOBJ.uf,
            StateSubscription: this.Checkout.customer.state_subscription
          }
          if (!this.checkStateSubscription(StateSubscriptionOBJ)) {
            return callback(new Error(`Inscrição Estadual inválida`))
          }
        }
        this.SetCustomAttribute(['inscricao_estadual', this.Checkout.customer.state_subscription])
        if (this.CustomAttributes) { this.SendCustomAttributes() }
        return callback()
      }
      return callback(new Error(`Inscrição Estadual inválida`))
    },
    clearCheckout() {
      this.SetCheckoutState(null)
    },
    async nextStep(Checkout) {
      this.Checkout.checkout_customer_optin = this.Checkout_customer_optin
      let $Checkout = this.Checkout
      this.setCheckoutStatus({
        ContactInfos: {
          update: 'LOADING'
        }
      })
      // try {
      //   if (this.$refs['Checkout.contact_info.b2c_form']) {
      //     await this.$refs['Checkout.contact_info.b2c_form'].validate()
      //   }
      //   if (this.$refs['Checkout.contact_info.b2b_form']) {
      //     await this.$refs['Checkout.contact_info.b2b_form'].validate()
      //   }
      // } catch (error) {
      //   this.setCheckoutStatus({
      //     ContactInfos: {
      //       update: 'ERROR'
      //     }
      //   })
      //   return false
      // }

      if (this.$refs['Checkout.gift_message']) {
        try {
          await this.$refs['Checkout.gift_message'].processGiftMessages()
        } catch (error) {
          this.setCheckoutStatus({
            ContactInfos: {
              update: 'ERROR'
            }
          })
          return false
        }
      }
      this.$refs['CustomerInformations'].validate(valid => {
        if (valid) {
          this.Checkout.activeStep = 1
          this.setAlert('close')
          this.sendContactInformation({
            checkoutId: this.$route.params.checkout_id,
            checkout: $Checkout
          })
        } else {
          this.setCheckoutStatus({
            ContactInfos: {
              update: 'ERROR'
            }
          })
          return false
        }
      })
    },
    setAlert(mode) {
      if (mode === 'show') {
        this.alert = true
      } else if (mode === 'close') {
        this.alert = false
      } else {
        this.alert = !this.alert
      }
    },

    setActiveInput(input) {
      this.activeInput = input
    },
    ShippingPersonNameValidator(rule, value, callback) {
      var nameSplitted = this.receiverFullName.split(' ')

      if (nameSplitted.length > 1 && nameSplitted[1]) {
        callback()
      } else {
        return callback(new Error(`Insira nome e sobrenome do destinatário`))
      }
    },
    async ValidateEmail(rule, value, callback) {
      try {
        await this.checkEmailFormat(value)
        callback()
      } catch (error) {
        return callback(new Error(`${error.message}`))
      }
    },
    ValidateZip(rule, value, callback) {
      this.getZipInfos(value).then((addressObj) => {
        this.UpdateCheckoutState({
          shipping_info: {
            ...this.Checkout.shipping_info,
            address: {
              ...this.Checkout.shipping_info.address,
              ...addressObj
            }
          }
        })
        // addressObj.neighborhood = ''
        if (this.disablePreRegisterFields !== true && Object.keys(this.preFilledAddressFields).length === 0) {
          this.preFilledAddressFields = Object.keys(addressObj).filter(field => addressObj[field])
        }

        callback()
      }).catch((err) => {
        return callback(new Error(`${err.message}`))
      })
    },
    setFindZipCodeModal(status) {
      this.showFindZipCodeModal = status || !this.showFindZipCodeModal
    },
    loginPage() {
      window.location.href = `${this.CheckoutConfig.shopify_api.domain}/account/login?checkout_url=/account?checkout_redirect`
    },
    redirectToLogout() {
      window.location.href = `${this.CheckoutConfig.shopify_api.domain}/account/logout?checkout_url=/account?checkout_redirect`
    },
    setDefaultReceiverName(firstName = this.Checkout.customer.first_name, lastName = this.Checkout.customer.last_name) {
      this.receiverFullName = `${firstName ? firstName.trim() : ''} ${lastName ? lastName.trim() : ''}`.trim()
      if ((!this.Checkout.shipping_info.person.first_name || !this.Checkout.shipping_info.person.last_name)) { }
    },
    isCustomerInformationValid(prop) {
      return this.validCustomerInformations.includes(prop)
    },
    onCustomerInformationsValidated(prop, isPropValid, propValidationMessage) {
      let $this = this
      let addValidCustomerInformation = (prop) => {
        if ($this.validCustomerInformations.includes(prop)) {
          return
        }
        return $this.validCustomerInformations.push(prop)
      }
      let removeValidCustomerInformation = (prop) => {
        $this.validCustomerInformations = $this.validCustomerInformations.filter(function (e) {
          return e !== prop
        })
      }
      if (isPropValid) {
        addValidCustomerInformation(prop)
      } else {
        removeValidCustomerInformation(prop)
      }
    },
    onSavedAddressSelected(address, preRegistration, allowDisablePreRegisterFields) {
      if (allowDisablePreRegisterFields === true) {
        this.disablePreRegisterFields = true
        this.setCustomerInformationsFromPreRegister(preRegistration)
      } else {
        this.disablePreRegisterFields = false
        this.setCustomerInformationsFromAddress(address)
      }
      this.setAddressInformations(address)
      this.setDefaultReceiverName()
    },
    setCustomerInformationsFromAddress(address) {
      this.Checkout.customer.first_name = address?.first_name !== undefined ? address.first_name : ''
      this.Checkout.customer.last_name = address?.last_name !== undefined ? address.last_name : ''
      this.Checkout.customer.phone = address?.phone !== undefined ? address.phone : ''
      this.Checkout.customer.document = address?.document !== undefined ? address.document : ''
    },
    setAddressInformations(address) {
      this.preFilledAddressFields = Object.keys(address).filter(field => address[field])
      this.Checkout.shipping_info.address.street = address?.street !== undefined ? address.street : ''
      this.Checkout.shipping_info.address.street_number = address?.number !== undefined ? address.number : ''
      this.Checkout.shipping_info.address.complement = address?.complement !== undefined ? address.complement : ''
      this.Checkout.shipping_info.address.neighborhood = address?.neighborhood !== undefined ? address.neighborhood : ''
      this.Checkout.shipping_info.address.city = address?.city !== undefined ? address.city : ''
      this.Checkout.shipping_info.address.state = address?.state !== undefined ? address.state : ''
      this.Checkout.shipping_info.address.zip_code = address?.zip_code !== undefined ? address.zip_code : ''
    },
    setCustomerInformationsFromPreRegister(preRegistration) {
      if (preRegistration != null) {
        this.preFilledCustomerFields = Object.keys(preRegistration).filter(field => preRegistration[field])
      }
      if (preRegistration?.documentType === 2) {
        this.Checkout.customer.state_subscription = preRegistration?.stateRegistration !== undefined ? preRegistration.stateRegistration : ''
        this.Checkout_customer_type = 'b2b'
      } else {
        this.Checkout_customer_type = 'b2c'
      }
      this.Checkout.customer.first_name = preRegistration?.firstName !== undefined ? preRegistration.firstName : ''
      this.Checkout.customer.last_name = preRegistration?.lastName !== undefined ? preRegistration.lastName : ''
      this.Checkout.customer.phone = preRegistration?.phone !== undefined ? preRegistration.phone : ''
      this.Checkout.customer.document = preRegistration?.document !== undefined ? preRegistration.document : ''
    }
  }
}
</script>

<style>
div .el-date-editor {
  min-width: 100%;
}

div .el-date-editor>.el-input__prefix {
  display: none;
}
</style>
