<template>
    <div class="el-form-outer">
        <el-form-item label="Endereços Cadastrados" :class="{ 'el-form-item--active': activeInput === 'Enderecos' }">
            <el-select v-model="currentAddress" value-key="full_address"
                @focus="setActiveInput('Enderecos')" @blur="setActiveInput(null)" @change="ChangeCurrentAddress()">
                <el-option v-for="item in addressList" :key="item.address_id" :label="item.full_address" :value="item"></el-option>
                <el-option key="newAddress" label="Usar um novo endereço" value="Usar um novo endereço"></el-option>
            </el-select>
        </el-form-item>
    </div>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
import apiClient from '../../api/index'
export default {
  name: 'ContactAddressSelector',
  data () {
    return {
      activeInput: null,
      addressList: [],
      currentAddress: null,
      preRegistration: null,
      allowEditPreRegisterInfo: false
    }
  },
  async mounted () {
    let customerId = localStorage.getItem('customer_id')
    if (customerId !== undefined) {
      var result = await apiClient.get(`customers/${customerId}/CustomerData`)
      this.addressList = result?.data.addresses
      this.preRegistration = result?.data.preRegistration
      this.allowDisablePreRegisterFields = result?.data.allowEditPreRegisterInfo
      this.currentAddress = this.addressList.find((address) => address.isDefault === true)
      if (this.currentAddress !== undefined) {
        this.ChangeCurrentAddress()
      }
    }
  },
  methods: {
    ChangeCurrentAddress () {
      if (this.allowDisablePreRegisterFields === true) {
        this.$emit('addressSelected', this.currentAddress, this.preRegistration, this.allowDisablePreRegisterFields)
      } else {
        this.$emit('addressSelected', this.currentAddress)
      }
    },
    setActiveInput (input) {
      this.activeInput = input
    }
  }
}
</script>
