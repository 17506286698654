/* eslint-disable no-unused-expressions */

import Boleto from 'boleto.js'

function CalculateExpirationDate(lineCode) {
  const baseDate = new Date(2022, 4, 29)
  const daysExpiration = parseInt(lineCode.substr(33, 4), 10)

  return baseDate.setDate(baseDate.getDate() + daysExpiration)
}

class BankSlipService {
  async GetBankslipDetails (lineCode) {
    var bankslip = new Boleto(lineCode)
    var bankslipExpirationDate = CalculateExpirationDate(lineCode)

    return {

      number: bankslip.number(),
      prettyNumber: bankslip.prettyNumber(),

      barcode: bankslip.barcode(),
      barcode_image: bankslip.toSVG(),
      checksum: bankslip.checksum(),

      amount: parseFloat(bankslip.amount()),
      prettyAmount: bankslip.prettyAmount(),
      bank: bankslip.bank(),
      currency: bankslip.currency(),
      expirationDate: bankslipExpirationDate
    }
  }
}

export default new BankSlipService()
